<template>
  <div>
    <div v-if="showOnlyToggles" class="d-flex align-items-center gap-3" style="margin-right: 20px;">
      <!-- First Toggle -->
      <div class="form-check form-switch d-flex align-items-center gap-2">
        <input class="form-check-input" type="checkbox" id="quickShip" v-model="isQuickShip" />
        <label class="form-check-label mb-0" for="quickShip">Show In-Stock Parts</label>
      </div>

      <!-- Second Toggle -->
      <div class="form-check form-switch d-flex align-items-center gap-2">
        <input class="form-check-input" type="checkbox" id="universalParts" v-model="isShowUniversalParts" />
        <label class="form-check-label mb-0" for="universalParts">Show Universal Parts</label>
      </div>
    </div>


    <div v-else class="offcanvas-lg offcanvas-start pe-lg-6 pt-3" id="filterSidebar">
      <!-- Vehicle Selector Card -->
      <div class="card mb-4">
        <div class="card-header" role="tab">
          <h4 class="h6 mb-0">
            <button class="btn btn-link text-decoration-none fw-bold card-toggle cardCollapseSign" type="button"
                    data-bs-toggle="collapse" data-bs-target="#vehicle-selector-body"
                    aria-expanded="false" aria-controls="vehicle-selector-body">
              Shop For Your Vehicle
            </button>
          </h4>
        </div>
        <div id="vehicle-selector-body" class="collapse show">
          <VehicleSelectorCompact />
        </div>
      </div>

      <div class="card mb-4" v-if="categoryFacets.length">
        <div class="card-header" role="tab">
          <h4 class="h6 mb-0">
            <button class="btn btn-link text-decoration-none fw-bold card-toggle cardCollapseSign" type="button"
                    data-bs-toggle="collapse" data-bs-target="#categories-body"
                    aria-expanded="true" aria-controls="categories-body">
              Categories
            </button>
          </h4>
        </div>
        <div id="categories-body" class="collapse show" style="margin: 10px 10px 0 20px;">
          <template v-for="(facet, type) in availableFacets" :key="type">
            <default-facet v-if="facet.type === 'categories'" :facet="facet" @facet-clicked="facetClicked" />
          </template>
        </div>
      </div>
      <!-- Facets Accordion Card -->
      <div class="card mb-4" v-if="availableFacets && availableFacets.length > 0">
        <div class="card-header" role="tab">
          <h4 class="h6 mb-0">
            <button class="btn btn-link text-decoration-none fw-bold card-toggle cardCollapseSign" type="button"
                    data-bs-toggle="collapse" data-bs-target="#facets-body"
                    aria-expanded="false" aria-controls="facets-body">
              Filters
            </button>
          </h4>
        </div>
        <div id="facets-body" class="collapse show card-body">
          <div class="accordion">
            <template v-for="(facet, type) in availableFacets" :key="type">
              <price-facet v-if="facet.type === 'price'" />
              <default-facet v-if="facet.type !== 'price' && facet.type !== 'categories'" :facet="facet" @facet-clicked="facetClicked" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>

<!--  <VehicleModal ref="vehicleModal" @vehicle-selected="onVehicleSelected" />-->

</template>

<script>
import DefaultFacet from './Facets/DefaultFacet.vue';
import PriceFacet from './Facets/PriceFacet.vue';
import VehicleSelectorCompact from '../Vehicle/VehicleSelectorCompact.vue';
import VehicleModal from '../Vehicle/VehicleModal.vue';
import { store } from './store';
export default {
  components: {
    DefaultFacet,
    PriceFacet,
    VehicleSelectorCompact,
    VehicleModal
  },
  props: {
    facets: {
      type: Object,
      required: true
    },
    rules: {
      type: Object,
      default: {
        maxPrice: 0
      }
    },
    showOnlyToggles: Boolean,

  },
  data() {
    return {
      modifiedData: false,
      isQuickShip: false,
      isShowUniversalParts: true,
      selectedFacets: {},
      store,
      isMobile: false,
      alreadyHasVehicle: false,
      selectedVehicle: null
    }
  },
  mounted() {
    // Check if universal parts is set in the URL
    let url = new URL(window.location.href);
    if (url.searchParams.get("universal") == 0) {
      this.isShowUniversalParts = false;
    };
    if (url.searchParams.get("qs") == "true") {
      this.isQuickShip = true;
    };

    if (window.vrdata?.vehicle?.model_id != 0) {
      let vehicle = window.vrdata.vehicle;

      // this.selectedYear = vehicle.year;
      // this.selectedMake = vehicle.make_id;
      // this.selectedModel = vehicle.model_id;
      this.selectedVehicle = vehicle;

      this.alreadyHasVehicle = true;
      this.modifiedData = false;
    }

    // Load the selected filters from the store
    this.loadSelectedFilters();

    this.initTooltips();
  },
  updated() {
    this.initTooltips();
  },
  methods: {
    checkMobileSize() {
      this.isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed
    },
    onVehicleSelected(vehicle) {
      // Refresh page with vehicle params
      let url = new URL(window.location.href);
      url.searchParams.set('class_8', vehicle.make_id);
      url.searchParams.set('class_9', vehicle.model_id);
      url.searchParams.set('year', vehicle.year);
      window.location.href = url.toString();
    },
    openVehicleModal() {
      this.$refs.vehicleModal.showModal();
    },
    facetClicked(type, filter) {

      if (type == 'price') {
        this.store.setSelectedFilter('price', filter.value);
        this.$emit('filters-updated', this.selectedFacets);
        this.modifiedData = true;
        return;
      }

      this.store.catSelected = false;
      if (type == 'categories_id') {
        this.store.catSelected = true;
      }

      this.store.toggleSelectedFilter(type, filter.value);

      this.$emit('filters-updated', this.selectedFacets);

      this.modifiedData = true;
    },
    removeFilter(type, value) {
      this.store.removeSelectedFilter(type, value);
      this.$emit('filters-updated', this.selectedFacets);
    },
    clearVehicle() {
      this.isShowUniversalParts = true;
      this.$emit('clear-vehicle');
    },
    updateUniversalParts() {
      this.$emit('facet-clicked', 'universal', Number(this.isShowUniversalParts));
    },

    resetButtonClicked() {
      this.modifiedData = false;
      this.isQuickShip = false;
      this.$emit('facets-reset');
    },
    initTooltips() {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      tooltipTriggerList.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover' }));
    },
    clearAllFacets() {
      this.selectedFacets = {};
      this.$emit('filters-updated', this.selectedFacets);
    },
    loadSelectedFilters() {
      let filters = store.selectedFilters;
      for (let [type, values] of Object.entries(filters)) {
        for (let value of values) {
          let facet = this.facets[type]?.filters.find(facet => facet.value == value);
          if (facet) {
            if (!this.selectedFacets[type]) {
              this.selectedFacets[type] = [];
            }
            this.selectedFacets[type].push(facet);
          }
        }
      }
    }
  },
  computed: {

    availableFacets() {
      if (!this.facets) {
        return [];
      }
      // Convert facets to array
      let facets = Object.values(this.facets);
      // Filter out facets with no filters

      return facets.filter(facet => {
        // Filter out price
        // if (facet.type == 'price') {
        //   return false;
        // }
        return facet.filters.length > 0;
      });
    },

    categoryFacets() {
      return Object.values(this.availableFacets).filter(facet => facet.type === 'categories');
    },

    hasSelectedFacets() {
      return Object.keys(this.selectedFacets).some(key => this.selectedFacets[key].length > 0);
    },

    selectedFilterLabels() {
      let labels = [];
      for (let type in this.store.selectedFilters) {
        for (let value of this.store.selectedFilters[type]) {
          let label = this.facets[type]?.filters.find(facet => facet.value == value)?.label;
          labels.push({
            type: type,
            value: value,
            label: label
          });
        }
      }
      return labels;
    }

  },
  watch: {
    isQuickShip() {
      this.store.setSelectedFilter('qs', this.isQuickShip ? 1 : 0);
      this.$emit('filters-updated', this.selectedFacets);
    },
    isShowUniversalParts() {
      this.store.setSelectedFilter('universal', this.isShowUniversalParts ? 1 : 0);
      this.$emit('filters-updated', this.selectedFacets);
    }
  }
}
</script>

<style scoped>
.reset-filters {
  cursor: pointer;
}

#filterSidebar {
  overflow: auto;
}

.cardCollapseSign {
  position: relative;
  padding-right: 0.1rem;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardCollapseSign::after {
  content: "\2303";
  font-size: 2em;
  transition: transform 0.3s ease;
  margin-left: auto;
}

.cardCollapseSign[aria-expanded="false"]::after {
  content: "\2304";
}

#facets-body {
  max-height: 1400px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #e0e0e0;
  padding-right: 8px;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ccc transparent; /* For Firefox */
  -webkit-overflow-scrolling: touch;
}

/* Scrollbar styling for Webkit browsers (Chrome, Safari) */
#facets-body::-webkit-scrollbar {
  width: 8px;
}

#facets-body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

#facets-body::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

#facets-body::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.card-toggle {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  padding-left: 0;
  color: #3c3c3c;
}

.card-toggle:hover {
  text-decoration: underline;
}
</style>