import { reactive } from "vue";

// Helper function to normalize values to strings
function normalizeValue(value) {
	return String(value);
}

// This is a global store for the product list
export const store = reactive({
	selectedFilters: {},
	catSelected: false,
	addSelectedFilter(type, value) {
		const normalizedValue = normalizeValue(value);
		if (!this.selectedFilters[type]) {
			this.selectedFilters[type] = new Set();
		}
		this.selectedFilters[type].add(normalizedValue);
		this.selectedFilters[type] = new Set([...this.selectedFilters[type]]);
	},

	setSelectedFilter(type, value) {
		const normalizedValue = normalizeValue(value);
		this.selectedFilters[type] = new Set([normalizedValue]);
	},
	removeSelectedFilterKey(type) {
		delete this.selectedFilters[type];
	},
	removeSelectedFilter(type, value) {
		const normalizedValue = normalizeValue(value);
		this.selectedFilters[type].delete(normalizedValue);
		if (this.selectedFilters[type].size === 0) {
			delete this.selectedFilters[type];
		} else {
			this.selectedFilters[type] = new Set([...this.selectedFilters[type]]);
		}
	},
	toggleSelectedFilter(type, value) {
		const normalizedValue = normalizeValue(value);
		if (this.selectedFilters[type] && this.selectedFilters[type].has(normalizedValue)) {
			console.debug('Removing filter', type, normalizedValue);
			this.removeSelectedFilter(type, normalizedValue);
		} else {
			console.debug('Adding filter', type, normalizedValue);
			this.addSelectedFilter(type, normalizedValue);
		}
	}
});


