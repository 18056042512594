<template>
	<div class="mt-4">


		<!-- Pagination -->
		<!-- <pagination :pagination="pagination" @page-change="setPage" /> -->
		<div v-if="discontinuedMessage && discontinuedMessage.length > 0" class="vr-content">
			<p class="text-danger"><i class="ci-announcement me-2"></i>{{ discontinuedMessage }}</p>
		</div>

		<!-- Landing Page Description -->
		<div v-if="landingPageDescription" v-html="landingPageDescription" class="vr-content"></div>

		<!-- Short Description -->
		<div v-if="shortDescription" v-html="shortDescription" class="vr-content">

		</div>

		<!-- Sorting -->
      <div class="d-flex flex-column flex-md-row justify-content-center align-items-center w-100 justify-content-md-end gap-2">
      <div class="dropdown me-md-2 d-lg-none">
        <button class="btn btn-outline-secondary dropdown-toggle" href="#shop-filters"
                data-bs-toggle="offcanvas" data-bs-target="#filterSidebar"
                aria-controls="filterSidebar" data-bs-theme="light">
          <i class="bi me-2" :class="modifiedData ? 'bi-funnel-fill' : 'bi-funnel'"></i>
          Filters
        </button>
      </div>

      <product-filters
          :facets="facets"
          @facet-clicked="updateFacets"
          @facets-reset="resetFacets"
          :rules="rules"
          @filters-updated="updateFilters"
          @clear-vehicle="clearVehicle"
          :showOnlyToggles="true"
      />

      <!-- Sort by dropdown -->
      <div class="d-flex align-items-center gap-2">
        <label class="form-check-label fw-bold text-nowrap mb-0">Sort by:</label>
        <select class="form-select" v-model="sort" @change="updateSort">
          <option v-for="(value, key) in sortOptions" :key="key" :value="key">{{ value }}</option>
        </select>
      </div>
    </div>


		<section class="container"></section>
		<div class="row">
			<aside class="col-lg-2" :style="isMobile ? {} : { width: '20%' }">
				<product-filters :facets="facets" @facet-clicked="updateFacets" @facets-reset="resetFacets" :rules="rules"
					@filters-updated="updateFilters" @clear-vehicle="clearVehicle" :showOnlyToggles="false"
					/>
			</aside>
			<div class="col">
			<div class="row pt-3 mx-n2 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 " v-show="loading">
				<div class="col px-2 mb-4" v-for="i in 24" :key="i">
					<!-- Loading placeholders -->
					<div class="card product-card shadow-sm" aria-hidden="true">
						<div class="position-relative placeholder-wave">
							<div class="card-img-top placeholder ratio ratio-16x9"></div>
							<i
							   class="ci-image position-absolute top-50 start-50 translate-middle fs-1 text-muted opacity-50"></i>
						</div>
						<div class="card-body py-2">
							<span class="product-meta d-block fs-xs pb-1 placeholder placeholder-sm"></span>
							<h2 class="card-title placeholder-glow">
								<span class="placeholder col-6"></span>
							</h2>

							<a tabindex="-1" class="bg-primary disabled placeholder col-8 placeholder-wave me-4"></a>
						</div>
					</div>
				</div>

			</div>

			<div class="row pt-3 mx-n2 row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-1 " v-show="!loading">
				<!-- Errors -->
				<div v-if="errorMessage" class="col-12 d-flex justify-content-center">
					<div class="alert alert-danger " role="alert">
						{{ errorMessage }}
					</div>
				</div>

				<div class="col px-2 mb-4" v-for="product in products" :key="product.products_id">
					<product-card :product="product" :isWholesale="isWholesale" />
				</div>
				<div v-if="products.length == 0" class="w-100 d-flex justify-content-center">
					<div class="text-center w-100">
						<h1>No products found</h1>
						<p class="text-muted">Try another search term, or removing a filter.</p>
						<button class="btn btn-primary" @click="resetFacets">Reset Filters</button>
					</div>
				</div>
			</div>
					<!-- Pagination -->
		<pagination :pagination="pagination" @page-change="setPage" />
		</div>

	</div>
</div>
</template>

<script>
import ProductCard from './ProductCard.vue';
import ProductFilters from './ProductFilters.vue';
import Pagination from '../common/Pagination.vue';
import { store } from './store';
export default {
	name: 'ProductList',
	components: {
		ProductCard,
		ProductFilters,
		Pagination
	},
	data() {
		return {
			products: [],
			// selectedFilters: {
			// },
			productsPerPage: 24,
			sort: 'r',
			sortOptions: {
				'rel': 'Relevance',
				'r': 'Recommended',
				'1a': 'Model: A to Z',
				'1d': 'Model: Z to A',
				'3a': 'Name: A to Z',
				'3d': 'Name: Z to A',
				'4a': 'Price: Low to High',
				'4d': 'Price: High to Low',
				'5a': 'Oldest Products',
				'5d': 'Newest Products'
			},
			loading: true,
			page: 1,
			categories_id: 0,
			pagination: {},
			isWholesale: false,
			facets: [],
			preloadData: true,
			isPreloaded: false,
			errorMessage: '',
			isTireCategory: false,
			isManufacturerUrl: false,
			isCategoryUrl: false,
			shortDescription: '',
			landingPageDescription: '',
			discontinuedMessage: '',
      isMobile: window.innerWidth < 992,
			rules: {
				// Rules for facets
			},
			store
		}
	},
	mounted() {
		window.addEventListener('popstate', this.handlePopState);
    window.addEventListener('resize', this.checkMobile);
	},

	beforeUnmount() {
		window.removeEventListener('popstate', this.handlePopState);
    window.removeEventListener('resize', this.checkMobile);
	},
	created() {
		if (window.vrdata.categories_id) {
			this.store.addSelectedFilter('categories_id', window.vrdata.categories_id);
		}
		if (window.vrdata.manufacturers_id) {
			this.store.addSelectedFilter('manufacturers_id', window.vrdata.manufacturers_id);
		}
		// Vehicle
		if (window.vrdata.vehicle.model_id != 0) {
			this.store.setSelectedFilter('class_9', window.vrdata.vehicle.model_id);
			this.store.setSelectedFilter('class_8', window.vrdata.vehicle.make_id);
		}

    if (window.vrdata.landing_page_description) {
      this.landingPageDescription = window.vrdata.landing_page_description;
    }

		// Check if the page is a manufacturer page
		let url = window.location.href;


		if (url.includes('-m-')) {
			this.isManufacturerUrl = true;
			this.store.addSelectedFilter('manufacturers_id', window.vrdata.manufacturers_id);
		}
		if (url.includes('-c-')) {
			this.isCategoryUrl = true;
		}

		// Get query params from url
		const urlParams = new URLSearchParams(window.location.search);
		// Get the page
		if (urlParams.has('page')) {
			this.page = urlParams.get('page');
		}

		// Sort
		if (urlParams.has('sort')) {
			this.sort = urlParams.get('sort');
		}

		// Get the facets
		for (let [key, value] of urlParams) {
			if (key != 'page') {
				if (typeof value == 'string') {
					value = value.split('~');
				}
				for (let v of value) {
					this.store.addSelectedFilter(key, v);
				}
			}
		}

		if (this.preloadData) {
			let data = window.VR_PRELOAD;
			if (data != null) {
				this.isPreloaded = true;
				this.products = data.products;
				this.loading = false;
				this.pagination = data.pagination;
				this.isWholesale = data.is_wholesale;
				this.facets = data.facets;
				this.rules.maxPrice = data.max_price;
				this.isTireCategory = data.is_tire_category;
				this.shortDescription = data.short_description;
				this.discontinuedMessage = data.discontinued_message;
				if (!urlParams.has('sort')) {
					this.sort = data.sort;
				}

				if (this.sort != 'rel') {
					// Remove relevance from sort
					delete this.sortOptions['rel'];
				}
			} else {
				this.getProducts();
			}

		} else {
			this.getProducts();

		}
	},
	methods: {
    checkMobile() {
      this.isMobile = window.innerWidth < 992; // Adjust breakpoint as needed
    },
		handlePopState(event) {
			// Extract new params from the URL
			const urlParams = new URLSearchParams(window.location.search);

			// Update the component state with the new params
			this.page = urlParams.get('page') || 1;
			this.sort = urlParams.get('sort') || 'r';

			// Update selected filters while preserving necessary IDs
			this.selectedFilters = {};
			
			// Preserve category ID if this is a category URL
			if (this.isCategoryUrl && window.vrdata.categories_id) {
				this.selectedFilters.categories_id = window.vrdata.categories_id;
			}
			
			// Preserve manufacturer ID if this is a manufacturer URL
			if (this.isManufacturerUrl && window.vrdata.manufacturers_id) {
				this.selectedFilters.manufacturers_id = window.vrdata.manufacturers_id;
			}

			// Add all other URL parameters
			for (let [key, value] of urlParams) {
				if (key !== 'page' && key !== 'sort') {
					// Don't override category_id on category URLs or manufacturer_id on manufacturer URLs
					if ((key === 'categories_id' && this.isCategoryUrl) || 
						(key === 'manufacturers_id' && this.isManufacturerUrl)) {
						continue;
					}
					this.selectedFilters[key] = value;
				}
			}

			// Fetch updated products
			this.getProducts();
		},
    updateFacets(facet, value) {
      if (value === '') {
        delete this.selectedFilters[facet];
      } else {
        this.selectedFilters[facet] = value;
      }
      this.page = 1;
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    },
    fetchCategories() {
      // Fetch categories for the manufacturer
      fetch(`/manufacturer-categories/${window.vrdata.manufacturers_id}`)
          .then(response => response.json())
          .then(data => {
            this.categories = data;
          })
          .catch(error => {
            console.error('Error fetching categories:', error);
          });
    },
		setPage(page) {
			this.page = page;
			this.getProducts();
		},
		clearVehicle() {
			// Delete the keys
			this.store.removeSelectedFilterKey('class_9');
			this.store.removeSelectedFilterKey('class_8');
			this.store.removeSelectedFilterKey('class_year');

			this.store.setSelectedFilter('universal', 1);

			// Clear window data
			window.vrdata.vehicle = {
				model_id: 0,
				make_id: 0,
				year: 0
			};
			this.setHeroTitle("Products");
			this.updateUrl();
			this.getProducts();
		},

		setHeroTitle(title, breadCrumb = "") {
			if (breadCrumb === "") {
				breadCrumb = title;
			}

			let titleEl = document.getElementById('hero-title');
			if (titleEl) {
				titleEl.innerHTML = title;
			}


			let breadcrumbEl = document.querySelector('.hero .breadcrumb .breadcrumb-item.active');
			if (breadcrumbEl) {
				breadcrumbEl.innerHTML = breadCrumb;
			}
		},

		updateFilters(filters) {
			if (Object.keys(filters).length > 0) {
				// Flatten the filters to type => [values]
				// this.selectedFilters = {};
				// for (let [type, value] of Object.entries(filters)) {

				// 	this.selectedFilters[type] = value.map(filter => filter.value);
				// }
				

			}

			this.page = 1;
			this.getProducts();
		},
		resetFacets() {
			this.store.selectedFilters = {};
			if (window.vrdata.categories_id) {
				this.store.addSelectedFilter('categories_id', window.vrdata.categories_id);
			}
			if (window.vrdata.manufacturers_id) {
				this.store.addSelectedFilter('manufacturers_id', window.vrdata.manufacturers_id);
			}
			// Vehicle
			if (window.vrdata.vehicle.model_id != 0) {
				this.store.addSelectedFilter('class_9', window.vrdata.vehicle.model_id);
			}
			this.page = 1;
			this.getProducts();
		},
		updateUrl() {
			// Get the url params
			let urlParams = new URLSearchParams();
			for (let [key, value] of Object.entries(this.flattenedSelectedFilters)) {
				if (key == 'manufacturers_id' && this.isManufacturerUrl) {
					continue
				}
				if (key == 'categories_id' && this.isCategoryUrl) {
					continue
				}


				if (key == 'universal') {
					if (value == true) {
						urlParams.set('universal', 1);
					} else {
						urlParams.set('universal', 0);
					}
					continue;
				}

				if (value !== '' && value !== null && value !== undefined) {

					urlParams.set(key, value);
				}
			}

			// Set the page
			if (this.page != 1) {
				urlParams.set('page', this.page);
			}

			// Sort
			urlParams.set('sort', this.sort);

			let url = `${location.pathname}?${urlParams}`;
			// Update the url and set browser history
			if (window.location.search !== `?${urlParams.toString()}`) {
				window.history.pushState({ urlPath: url }, '', url);
			}

		},

		getProducts() {
			this.loading = true;
			// Set the url params
			fetch(`/products_ajax.php?` + new URLSearchParams({
				page: this.page,
				sort: this.sort,
				is_tire_category: this.isTireCategory,
				...this.flattenedSelectedFilters
			}))
				.then(response => response.json())
				.then(data => {
					this.loading = false;
					this.products = data.products;
					this.pagination = data.pagination;
					this.isWholesale = data.is_wholesale;
          if (this.store.catSelected === true) {
            this.facets = data.facets;
          }
					// this.rules.maxPrice = data.max_price ? data.max_price : 100;
					this.updateUrl();
				})
				.catch(error => {
					console.error(error);
					this.errorMessage = "There was an error loading the products. Please try again later."
					this.loading = false;
				});
		},
		updateSort(sort) {

			this.page = 1;
			this.updateUrl();
			this.getProducts();

		}
	},
	watch: {
		page(newValue, oldValue) {
			// this.getProducts();
			// Scroll to top element
			// Get the element:
			// let el = document.getElementById('product-list');
			// // Get the top position of the element
			// let topPos = el.offsetTop;
			// // Scroll to that position
			// window.scrollTo({
			// 	top: topPos - 100,
			// 	behavior: 'smooth'
			// });

		}
	},
	computed: {
		flattenedSelectedFilters() {
			let flattenedFilters = {};
			for (let [type, values] of Object.entries(this.store.selectedFilters)) {

				if (type == 'categories') {
					type = 'categories_id';
				}
				
				if (type == 'manufacturers') {
					type = 'manufacturers_id';
				}

				// Check if the values is an array
				if (Array.isArray(values) || values instanceof Set) {
					flattenedFilters[type] = [...values].join('~');
				} else {
					flattenedFilters[type] = values;
				}
			}
			return flattenedFilters;
		}
	}
}
</script>
