<template>
	<div class="accordion-item border-0 pb-1 pb-xl-2"><!--    v-show="canBeRemoved"-->
		<h3 class="accordion-header" v-if="facet.type != 'categories'">
			<button class="accordion-button p-0 pb-3" :href="`#${facet.id}`" role="button" data-bs-toggle="collapse"
					aria-expanded="false" aria-controls="{{facet.id}}">
				{{ facet.name }}
			</button>
		</h3>
		<div class="accordion-collapse collapse show" :id="facet.id">
			<div class="accordion-body p-0 pb-4 mb-1 mb-xl-2" >
				<!-- Search Bar -->
				<div class="position-relative mb-1 mt-1" v-if="facet.filters.length > 8">
					<i class="ci-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
					<input class="brands-search form-control form-icon-start" type="text" placeholder="Search"
						   v-model="searchText">
					<button v-show="searchText.length > 0" @click="clearSearch"
							class="btn btn-sm btn-outline-secondary w-auto border-0 p-1 position-absolute top-50 end-0 translate-middle-y me-2 opacity-0">
						<svg class="opacity-75" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
							<path
								  d="M18.619 5.381a.875.875 0 0 1 0 1.238l-12 12A.875.875 0 0 1 5.38 17.38l12-12a.875.875 0 0 1 1.238 0Z">
							</path>
							<path
								  d="M5.381 5.381a.875.875 0 0 1 1.238 0l12 12a.875.875 0 1 1-1.238 1.238l-12-12a.875.875 0 0 1 0-1.238Z">
							</path>
						</svg></button>
				</div>

				<ul class="nav flex-column pe-3 flex-nowrap" style="max-height: 12rem; overflow-y: scroll; padding-top: 10px;">

					<li class="nav-item w-100" v-for="filter in filteredFacets">
						<div class="form-check mb-0" @click.capture="facetClicked($event, filter)" :class="{'active': isFilterSelected(filter.value)}">
							<input 
								type="checkbox" 
								class="form-check-input" 
								:id="`facet-${facet.type}-${filter.value}`" 
								:checked="isFilterSelected(filter.value)"
							>
							<label 
								:for="`facet-${facet.type}-${filter.value}`" 
								class="form-check-label text-body-emphasis" 
							>
								{{ filter.label }}
								<span class="fs-xs text-body-secondary ms-1">	{{ filter.count ? '(' + filter.count + ')' : '' }}</span>
							</label>
						</div>
					</li>

				</ul>

			</div>
		</div>
	</div>
</template>

<script>
import { store } from '../store';

export default {
	props: {
		facet: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			searchText: '',
			store
		};
	},
	watch: {
		facet(newValue, oldValue) {
			this.searchText = '';
		}
	},
	methods: {
		facetClicked(event, filter) {
			// If the user did not click the input element, prevent default action
			if (event.target.tagName.toLowerCase() !== 'input') {
				event.preventDefault();
			}
			this.$emit('facet-clicked', this.facetType, filter);
		},

		isFacetExpanded(filters) {
			for (let facet of filters) {
				if (facet.undo != '') {
					return true;
				}
			}
			return false;
		},
		clearSearch() {
			this.searchText = '';
		},

		isFilterSelected(filterValue) {

			if (this.selectedFacets) {
				return this.selectedFacets.has(String(filterValue));
			}
			return false;
		},
	},
	computed: {
		filteredFacets() {
			// Filter facets by search text
			if (this.searchText != '') {
				let filters = this.facet.filters.filter(facet => {
					return facet.label.toLowerCase().includes(this.searchText.toLowerCase());
				});
				return filters;
			}
			return this.facet.filters;
		},
		selectedFacets() {
			let type = this.facetType;
			// Convert filterValue to string
			let value = this.store.selectedFilters[type];
			return value || false;

		},
		facetType() {
			if (this.facet.type == 'categories') {
				return 'categories_id';
			} else if (this.facet.type == 'manufacturers') {
				return 'manufacturers_id';
			}
			return this.facet.type;
		},
		canBeRemoved() {
			// For manufacturers and categories, we need to check the url. If the url is a manufacturer or category, we can't remove it.
			if (this.facet.type == 'categories') {
				let url = new URL(window.location.href);

				// Check if url has  '-c-'
				if (url.pathname.includes('-c-')) {
					return false;
				}
			} else if (this.facet.type == 'manufacturers') {
				let url = new URL(window.location.href);

				// Check if url has  '-m-'
				if (url.pathname.includes('-m-')) {
					return false;
				}
			}
			return true;
		}

	},

}
</script>

<style lang="scss" scoped>
.facet-link {
	cursor: pointer;
}

.form-check-label {
	transition: color 0.2s ease;
	
	&:hover {
		color: var(--bs-primary) !important;
		cursor: pointer;
	}


}

.form-check.active  {
	.form-check-label {
		font-weight: 600;
	}
}
</style>