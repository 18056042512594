<template>
  <div>
    <!-- Manufacturer Description Section -->
    <div v-if="computedShortDescription" class="manufacturer-description pt-4">
      <p v-html="computedShortDescription"></p>
    </div>
    <!-- Top 6 Categories Section -->
    <div class="row row-cols-lg-6 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 pt-sm-3 justify-content-center d-flex">
      <div class="col mb-2" v-for="category in categories" :key="category.categories_id">
        <div class="card category-card shadow-none h-100">
            <a :href="generateCategoryUrl(category)"
               class="card-title product-title fs-base fs-5 py-1 px-1 mb-0 text-center align-middle text-dark"
               style="min-height: 42px;">
              {{ category.manufacturer_category_card_header || this.manufacturerName }}
            </a>
          <a :href="generateCategoryUrl(category)" class="d-block w-100 d-flex justify-content-center category-image">
            <img class="card-img-top" :src="`${category.image_url}`" :alt="category.categories_name" loading="lazy">
          </a>
            <a :href="generateCategoryUrl(category)"
               class="card-title product-title fs-base fs-6 py-1 px-1 mb-0 text-center align-middle text-dark"
               style="min-height: 42px;">
              {{ category.manufacturer_category_card_text || category.categories_name}}
            </a>
        </div>
      </div>
    </div>
    <!-- Custom Divider -->
    <div class="custom-divider"></div>


    <!-- Newest 5 Products Section -->
    <div v-if="newestProducts.length > 0" class="mb-5">
      <div class="newest-products-title">
        <p>Newest Products for {{this.manufacturerName}}</p>
      </div>
      <div class="row pt-3 mx-n2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-1">
        <div class="col px-2 mb-4" v-for="product in newestProducts" :key="product.products_id">
          <product-card
            :product="product"
            :isWholesale="product.is_wholesale"
            @error="handleProductCardError"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <p>No newest products found.</p>
    </div>
    <!-- Custom Divider -->
    <div class="custom-divider"></div>
    <div class="newest-products-title">
      <p>Other Products for {{this.manufacturerName}}</p>
    </div>

    <!-- Sorting -->
    <div class="d-flex flex-column flex-md-row justify-content-center align-items-center w-100 justify-content-md-end gap-2">
      <div class="dropdown me-md-2 d-lg-none">
        <button class="btn btn-outline-secondary dropdown-toggle" href="#shop-filters"
                data-bs-toggle="offcanvas" data-bs-target="#filterSidebar"
                aria-controls="filterSidebar" data-bs-theme="light">
          <i class="bi me-2" :class="modifiedData ? 'bi-funnel-fill' : 'bi-funnel'"></i>
          Filters
        </button>
      </div>

      <product-filters
          :facets="facets"
          @facet-clicked="updateFacets"
          @facets-reset="resetFacets"
          :rules="rules"
          @filters-updated="updateFilters"
          @clear-vehicle="clearVehicle"
          :showOnlyToggles="true"
      />

      <!-- Sort by dropdown -->
      <div class="d-flex align-items-center gap-2">
        <label class="form-check-label fw-bold text-nowrap mb-0">Sort by:</label>
        <select class="form-select" v-model="sort" @change="updateSort">
          <option v-for="(value, key) in sortOptions" :key="key" :value="key">{{ value }}</option>
        </select>
      </div>
    </div>

    <!-- Product List Section -->
    <div class="row">
      <!-- Category Filters -->
      <aside class="col-lg-2" :style="isMobile ? {} : { width: '20%' }">
        <product-filters :facets="facets" @facet-clicked="updateFacets" @facets-reset="resetFacets" :rules="rules"
                         @filters-updated="updateFilters" @clear-vehicle="clearVehicle"/>
      </aside>
      <div class="col">
        <div class="row pt-3 mx-n2 row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-cols-1 " v-show="loading">
          <div class="col px-2 mb-4" v-for="i in 24" :key="i">
            <!-- Loading placeholders -->
            <div class="card product-card shadow-sm" aria-hidden="true">
              <div class="position-relative placeholder-wave">
                <div class="card-img-top placeholder ratio ratio-16x9"></div>
                <i class="ci-image position-absolute top-50 start-50 translate-middle fs-1 text-muted opacity-50"></i>
              </div>
              <div class="card-body py-2">
                <span class="product-meta d-block fs-xs pb-1 placeholder placeholder-sm"></span>
                <h2 class="card-title placeholder-glow">
                  <span class="placeholder col-6"></span>
                </h2>
                <a tabindex="-1" class="bg-primary disabled placeholder col-8 placeholder-wave me-4"></a>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-3 mx-n2 row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-cols-1 " v-show="!loading">
          <!-- Errors -->
          <div v-if="errorMessage" class="col-12 d-flex justify-content-center">
            <div class="alert alert-danger " role="alert">
              {{ errorMessage }}
            </div>
          </div>

          <div class="col px-2 mb-4" v-for="product in products" :key="product.products_id">
            <product-card :product="product" :isWholesale="isWholesale"/>
          </div>
          <div v-if="products.length === 0" class="w-100 d-flex justify-content-center">
            <div class="text-center w-100">
              <h1>No products found</h1>
              <p class="text-muted">Try another search term, or removing a filter.</p>
              <button class="btn btn-primary" @click="resetFacets">Reset Filters</button>
            </div>
          </div>
        </div>
        <!-- Pagination -->
        <pagination :pagination="pagination" @page-change="setPage"/>
      </div>
    </div>
  </div>
  <!-- Manufacturer Description_2 Section -->
  <div v-if="computedLongDescription" class="manufacturer-description-long">
    <p v-html="computedLongDescription"></p>
  </div>
</template>

<script>
import ProductCard from '../Product/ProductCard.vue';
// import ProductFilters from './ProductFiltersManufacturerView.vue';
import ProductFilters from '../Product/ProductFilters.vue';
import Pagination from '../common/Pagination.vue';
import { store } from '../Product/store';

export default {
  name: 'CategoryList',
  components: {
    ProductCard,
    ProductFilters,
    Pagination
  },
  data() {
    return {
      products: [],
      categories: [],
      // selectedFilters: {},
      productsPerPage: 24,
      sort: 'r',
      sortOptions: {
        'rel': 'Relevance',
        'r': 'Recommended',
        '1a': 'Model: A to Z',
        '1d': 'Model: Z to A',
        '3a': 'Name: A to Z',
        '3d': 'Name: Z to A',
        '4a': 'Price: Low to High',
        '4d': 'Price: High to Low',
        '5a': 'Oldest Products',
        '5d': 'Newest Products'
      },
      loading: true,
      page: 1,
      categories_id: 0,
      pagination: {},
      isWholesale: false,
      facets: [],
      preloadData: true,
      isPreloaded: false,
      errorMessage: '',
      isTireCategory: false,
      isManufacturerUrl: false,
      isCategoryUrl: false,
      shortDescription: '',
      longDescription: '',
      manufacturerName: '',
      rules: {},
      newestProducts: [],
      rawData: null,
      isMobile: window.innerWidth < 992,
      store
    };
  },
  created() {
    if (window.vrdata.categories_id) {
      this.store.addSelectedFilter('categories_id', window.vrdata.categories_id);
    }
    if (window.vrdata.manufacturers_id) {
      this.store.addSelectedFilter('manufacturers_id', window.vrdata.manufacturers_id);
    }
    // Vehicle
    if (window.vrdata.vehicle.model_id != 0) {
      this.store.setSelectedFilter('class_9', window.vrdata.vehicle.model_id);
      this.store.setSelectedFilter('class_8', window.vrdata.vehicle.make_id);
    }

    if (window.vrdata.landing_page_description) {
      this.landingPageDescription = window.vrdata.landing_page_description;
    }

    // Check if the page is a manufacturer page
    let url = window.location.href;


    if (url.includes('-m-')) {
      this.isManufacturerUrl = true;
      this.store.addSelectedFilter('manufacturers_id', window.vrdata.manufacturers_id);
    }
    if (url.includes('-c-')) {
      this.isCategoryUrl = true;
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('page')) {
      this.page = urlParams.get('page');
    }

    // Sort
    if (urlParams.has('sort')) {
      this.sort = urlParams.get('sort');
    }

    // Initialize other selectedFilters
    for (let [key, value] of urlParams) {
      if (key !== 'page') {
        // this.selectedFilters[key] = value;
        if (typeof value == 'string') {
          value = value.split('~');
        }
        for (let v of value) {
          this.store.addSelectedFilter(key, v);
        }
      }
    }

    if (this.preloadData) {
      let data = window.VR_PRELOAD;
      if (data != null) {
        this.isPreloaded = true;
        this.products = data.products;
        this.loading = false;
        this.pagination = {
          ...data.pagination,
          currentPage: this.page // Ensure the pagination reflects the current page
        };
        this.isWholesale = data.is_wholesale;
        this.facets = data.facets;
        this.rules.maxPrice = data.max_price;
        this.isTireCategory = data.is_tire_category;
        this.shortDescription = data.short_description;
        this.discontinuedMessage = data.discontinued_message;
        if (!urlParams.has('sort')) {
          this.sort = data.sort;
        }

        // Remove relevance if not default sort
        if (this.sort != 'rel') {
          delete this.sortOptions['rel'];
        }
      } else {
        this.getProducts();
        this.fetchNewestProducts();
        this.fetchManufacturerDescriptions();
        this.fetchCategories(); // Fetch category-specific filters
      }

    } else {
      // Fetch data if no preload
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
      this.fetchNewestProducts();
    }
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth < 992; // Adjust breakpoint as needed
    },
    handlePopState(event) {
      // Extract new params from the URL
      const urlParams = new URLSearchParams(window.location.search);

      // Update the component state with the new params
      this.page = urlParams.get('page') || 1;
      this.sort = urlParams.get('sort') || 'r';

      // Update selected filters while preserving necessary IDs
      this.selectedFilters = {};

      // Preserve category ID if this is a category URL
      if (this.isCategoryUrl && window.vrdata.categories_id) {
        this.selectedFilters.categories_id = window.vrdata.categories_id;
      }

      // Preserve manufacturer ID if this is a manufacturer URL
      if (this.isManufacturerUrl && window.vrdata.manufacturers_id) {
        this.selectedFilters.manufacturers_id = window.vrdata.manufacturers_id;
      }

      // Add all other URL parameters
      for (let [key, value] of urlParams) {
        if (key !== 'page' && key !== 'sort') {
          // Don't override category_id on category URLs or manufacturer_id on manufacturer URLs
          if ((key === 'categories_id' && this.isCategoryUrl) ||
              (key === 'manufacturers_id' && this.isManufacturerUrl)) {
            continue;
          }
          this.selectedFilters[key] = value;
        }
      }

      // Fetch updated products
      this.getProducts();
    },
    generateCategoryUrl(category) {
      return category.canonical_url + `?manufacturers_id=${this.store.selectedFilters.manufacturers_id}`;
    },
    fetchCategories() {
      // Fetch categories for the manufacturer
      fetch(`/manufacturer-categories/${window.vrdata.manufacturers_id}`)
          .then(response => response.json())
          .then(data => {
            this.categories = data;
          })
          .catch(error => {
            console.error('Error fetching categories:', error);
          });
    },
    fetchManufacturerDescriptions() {
      // Fetch categories for the manufacturer
      fetch(`/manufacturer-descriptions/${window.vrdata.manufacturers_id}`)
          .then(response => response.json())
          .then(data => {
            this.manufacturerName = data[0]?.manufacturers_name;
            this.longDescription = data[0]?.manufacturers_description;
            this.shortDescription = data[0]?.manufacturers_description_2;
          })
          .catch(error => {
            console.error('Error fetching categories:', error);
          });
    },
    getProducts() {
      this.loading = true;
      fetch(`/products_ajax.php?` + new URLSearchParams({
        page: this.page,
        sort: this.sort,
        is_tire_category: this.isTireCategory,
        ...this.flattenedSelectedFilters
      }))
          .then(response => response.json())
          .then(data => {
            this.loading = false;
            this.products = data.products;
            this.pagination = data.pagination;
            this.isWholesale = data.is_wholesale;
            if (this.store.catSelected === true) {
              this.facets = data.facets;
            }
            this.updateUrl();
          })
          .catch(error => {
            console.error('Error loading products:', error);
            this.errorMessage = 'There was an error loading the products. Please try again later.';
            this.loading = false;
          });
    },
    setPage(page) {
      this.page = page;
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    },
    updateSort(sort) {

      this.page = 1;
      this.updateUrl();
      this.getProducts();

    },
    resetFacets() {
      this.store.selectedFilters = {};
      if (window.vrdata.categories_id) {
        this.store.addSelectedFilter('categories_id', window.vrdata.categories_id);
      }
      if (window.vrdata.manufacturers_id) {
        this.store.addSelectedFilter('manufacturers_id', window.vrdata.vehicle.model_id);
      }
      // Vehicle
      if (window.vrdata.vehicle.model_id != 0) {
        this.store.addSelectedFilter('class_9', window.vrdata.vehicle.model_id);
      }
      this.page = 1;
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    },
    updateFacets(facet, value) {

      if (value === '') {
        delete this.selectedFilters[facet];
      } else {
        this.selectedFilters[facet] = value;
      }
      this.page = 1;
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    },

    updateFilters(filters) {
      if (Object.keys(filters).length > 0) {
        // Flatten the filters to type => [values]
        this.selectedFilters = {};
        for (let [type, value] of Object.entries(filters)) {

        	this.selectedFilters[type] = value.map(filter => filter.value);
        }
      }

      this.page = 1;
      this.getProducts();
    },
    clearVehicle() {
      // Delete the keys
      this.store.removeSelectedFilterKey('class_9');
      this.store.removeSelectedFilterKey('class_8');
      this.store.removeSelectedFilterKey('class_year');

      this.store.setSelectedFilter('universal', 1);

      // Clear window data
      window.vrdata.vehicle = {
        model_id: 0,
        make_id: 0,
        year: 0
      };
      this.setHeroTitle("Products");
      this.updateUrl();
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    },

    setHeroTitle(title, breadCrumb = "") {
      if (breadCrumb === "") {
        breadCrumb = title;
      }

      let titleEl = document.getElementById('hero-title');
      if (titleEl) {
        titleEl.innerHTML = title;
      }


      let breadcrumbEl = document.querySelector('.hero .breadcrumb .breadcrumb-item.active');
      if (breadcrumbEl) {
        breadcrumbEl.innerHTML = breadCrumb;
      }
    },
    updateUrl() {
      const urlParams = new URLSearchParams();

      // Add selected filters to the URL
      for (const [key, value] of Object.entries(this.flattenedSelectedFilters)) {
        if (key === 'manufacturers_id' && this.isManufacturerUrl) continue; // Skip manufacturer if in URL already
        if (key === 'categories_id' && this.isCategoryUrl) continue;       // Skip category if in URL already

        if (key == 'universal') {
          if (value == true) {
            urlParams.set('universal', 1);
          } else {
            urlParams.set('universal', 0);
          }
          continue;
        }

        if (value !== '' && value !== null && value !== undefined) {

          urlParams.set(key, value);
        }
      }

      // Add the current page if it's not the default (1)
      if (this.page !== 1) {
        urlParams.set('page', this.page);
      }

      // Update the browser URL
      // if (urlParams.size > 0) {
      //   window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
      // }

      let url = `${location.pathname}?${urlParams}`;
      // Update the url and set browser history
      if (window.location.search !== `?${urlParams.toString()}`) {
        window.history.pushState({ urlPath: url }, '', url);
      }
    },
    fetchNewestProducts() {
      const manufacturerId = window.vrdata.manufacturers_id;
      fetch(`/products_ajax.php?sort=5d&new_manufacturer_products=1&manufacturers_id=${manufacturerId}`)
        .then(response => response.json())
        .then(data => {
          this.loading = false;
          this.newestProducts = data.products;
          this.updateUrl();
          this.rawData = JSON.stringify(data);
        })
        .catch(error => {
          console.error('Error fetching newest products:', error);
        });
    },
    formatPrice(price) {
      return `$${parseFloat(price).toFixed(2)}`;
    },
    handleProductCardError(error) {
      console.error('ProductCard error:', error);
    },
    formatProduct(product) {
      return {
        ...product,
        products_price: parseFloat(product.products_price),
        final_price: parseFloat(product.final_price),
        products_quantity: parseInt(product.products_quantity),
        products_weight: parseFloat(product.products_weight),
        products_status: parseInt(product.products_status),
        manufacturers_id: parseInt(product.manufacturers_id),
        products_tax_class_id: parseInt(product.products_tax_class_id),
      };
    },
    formatProductForCard(product) {
      return {
        products_id: product.products_id,
        products_name: product.products_name,
        products_image: product.products_image,
        products_image_lrg: product.products_image_lrg,
        products_price: product.products_price,
        final_price: product.priceData?.finalPrice || product.products_price,
        products_tax_class_id: product.products_tax_class_id,
        products_quantity: product.products_quantity,
        manufacturers_name: product.manufacturers_name,
        products_model: product.products_model,
        products_description: product.products_description,
        products_url: product.products_url,
        in_stock: product.in_stock,
        is_wholesale: product.is_wholesale,
        link: product.products_url,
        priceData: {
          fullPrice: product.priceData?.fullPrice || product.products_price,
          finalPrice: product.priceData?.finalPrice || product.products_price,
          discountPrice: product.priceData?.discountPrice || product.products_price,
          isSale: product.priceData?.isSale || false,
          isCoupon: product.priceData?.isCoupon || false,
          couponCode: product.priceData?.couponCode || null
        },
        low_stock: product.low_stock || false
      };
    },
  },
  watch: {
    sort(newValue) {
      this.page = 1;
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    },
    page(newValue) {
      this.pagination.currentPage = newValue;
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    }
  },
  computed: {
    flattenedSelectedFilters() {
      let flattenedFilters = {};
      for (let [type, values] of Object.entries(this.store.selectedFilters)) {

        if (type == 'categories') {
          type = 'categories_id';
        }

        if (type == 'manufacturers') {
          type = 'manufacturers_id';
        }

        // Check if the values is an array
        if (Array.isArray(values) || values instanceof Set) {
          flattenedFilters[type] = [...values].join('~');
        } else {
          flattenedFilters[type] = values;
        }
      }
      return flattenedFilters;
    },
    computedShortDescription() {
      return this.shortDescription
          ? this.shortDescription
          : `Top Categories for ${this.manufacturerName}`;
    },
    computedLongDescription() {
      return this.longDescription
          ? this.longDescription
          : `${this.manufacturerName}`;
    }
  },
  mounted() {
    this.fetchNewestProducts();
    window.addEventListener('popstate', this.handlePopState);
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
    window.removeEventListener('resize', this.checkMobile);
  }
};
</script>
<style scoped>
.manufacturer-description-long {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.2rem;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.newest-products-title {
  margin-bottom: 20px;
  padding-top: 10px;
  text-align: center;
  font-size: 1.2rem;
  color: #333;
}
.custom-divider {
  width: 100%;
  height: 2px;
  background-color: #ccc; /* Change color to what you prefer */
  margin: 20px 0;
  border-radius: 5px;
  /* You can add a shadow or other styling to make it more prominent */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
</style>

