<template>
	<div class="search-select" ref="searchSelect">
		<!-- Desktop -->
		<div class="form-floating" v-if="!isMobile">
			<input type="text" class="form-control" v-model="searchInput" @input="handleInput" :id="id"
				    @keydown="handleKeyDown" ref="searchInput" @focus="showResults = true"
				   placeholder="" v-bind="$attrs" />
			<i class="bi bi-chevron-down position-absolute end-0 top-50 me-4 "
			   :class="{ 'rotate-180': showResults }"></i>

			<label :for="id">{{ label }}</label>
		</div>
		<!-- Desktop Results -->
		<ul v-show="showResults" class="dropdown-menu show result-list">
			<li v-for="(result, index) in results" :key="result.value" class="dropdown-item" tabindex="0"
				:class="{ 'active': index === focusedIndex }" @click="handleClick(result)" ref="listItem"
				@keydown="handleKeyDown($event, result)">
				{{ result.label }}
			</li>
		</ul>
		<!-- Mobile -->
		<div class="form-floating mb-3" v-if="isMobile">
			<select aria-label="Vehicle Year" class="form-select" v-model="value" v-bind="$attrs" placeholder="" :id="id" >

				<option v-for="(result, index) in results" :key="result.value" :value="result.value">{{ result.label }}
				</option>

			</select>
			<label :for="id">{{ label }}</label>
		</div>

	</div>

</template>

<script>




export default {
	props: {
		searchItems: {
			type: Array,
			required: true,
		},
		searchFunction: {
			type: Function,
			required: false,
			default: null,
		},
		searchDelay: {
			type: Number,
			default: 300,
		},
		minSearchLength: {
			type: Number,
			default: 1,
		},
		label: {
			type: String,
			default: 'Search...',
		},
		defaultItem: {
			type: [String, Number],
			default: null,
		},
		modelValue: {
			type: [String, Number],
			default: null,
		},
		id: {
			type: String,
			default(){
				return `search-select-${Math.random().toString(36).substring(7)}`;
			}
		},


	},
	inheritAttrs: false,
	emits: ['update:modelValue'],
	data() {
		return {
			searchInput: '',
			// results: [],
			searchTimeout: null,
			focusedIndex: -1,
			showResults: false,
			isMobile: false,
		};
	},
	mounted() {
		this.checkMobileSize();
		document.addEventListener('mousedown', this.handleClickOutside);
		window.addEventListener('resize', this.checkMobileSize);
	},
	beforeDestroy() {
		document.removeEventListener('mousedown', this.handleClickOutside);
		window.removeEventListener('resize', this.checkMobileSize);
	},
	methods: {
		checkMobileSize() {
			this.isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed
		},
		async handleInput() {
			clearTimeout(this.searchTimeout);
		},
		handleClick(result) {
			this.selectItem(result);
		},

		handleClickOutside(event) {
			if (this.$refs.searchSelect && !this.$refs.searchSelect.contains(event.target)) {
				this.showResults = false;
			}
		},

		clearSelection() {
			this.value = null;
			this.searchInput = '';
			this.$nextTick(function () {
				this.$refs.searchInput.focus();

			})
			this.$emit('update:modelValue', null)
		},
		handleKeyDown(event, result) {
			if (event.key === 'ArrowUp') {
				event.preventDefault();
				this.focusPrevious();
			} else if (event.key === 'ArrowDown') {
				event.preventDefault();
				this.focusNext();
			} else if (event.key === 'Enter') {
				event.preventDefault();

				// TODO: Unified handling for tab and up/down arrows
				if (result) { // They are tabbing
					this.selectItem(result);
				}else{ // They are using up/down arrows
					this.selectFocusedItem();
				}

			} else if (event.key === 'Tab' && this.results.length === 1) {
				event.preventDefault(); // Prevent default to manage selection first
				this.selectItem(this.results[0]);
				this.$nextTick(() => {
					this.moveFocusToNextComponent();
				});
			}
		},

		focusPrevious() {
			if (this.focusedIndex > 0) {
				this.focusedIndex--;
			} else {
				this.focusedIndex = this.results.length - 1; // Cycle back to the last item if at the start
			}
			this.scrollIntoView();
		},

		focusNext() {
			if (this.focusedIndex < this.results.length - 1) {
				this.focusedIndex++;
			} else {
				this.focusedIndex = 0; // Cycle to the first item if at the end
			}
			this.scrollIntoView();
		},
		selectFocusedItem() {
			if (this.focusedIndex >= 0 && this.focusedIndex < this.results.length) {
				this.selectItem(this.results[this.focusedIndex]);
			}
		},
		selectItem(item) {
			this.value = item.value;
			this.searchInput = item.label;
			this.showResults = false;
			this.focusedIndex = -1;
		},
		scrollIntoView() {
			this.$nextTick(() => {
				const listItem = this.$refs.listItem[this.focusedIndex];
				if (listItem) {
					listItem.scrollIntoView({
						behavior: 'smooth',
						block: 'nearest',
						inline: 'nearest',
					});
				}
			});
		},
		moveFocusToNextComponent() {
			let parentColumn = this.$el.parentElement; // Get the parent <div class="col">
			let nextColumn = parentColumn.nextElementSibling; // Get the next sibling column
			if (nextColumn) {
				let nextSearchSelect = nextColumn.querySelector('.search-select');
				if (nextSearchSelect) {
					let inputField = nextSearchSelect.querySelector('input');
					if (inputField) {
						inputField.focus(); // Focus the input field of the next search-select component
					}
				}
			} else {
				document.body.focus(); // Fallback if no next component
			}
		},
	},
	computed: {
		value: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},

		results() {
			if (this.searchInput.length >= this.minSearchLength) {
				return this.searchItems.filter(item =>
					item.label.toLowerCase().includes(this.searchInput.toLowerCase()),
				).sort((a, b) => a.label.localeCompare(b.label));
			} else {
				return this.searchItems
			}
		}

	},
	watch: {
		searchItems(items) {
			if (this.value === null) {
				let item = items.filter(item => item.value == this.defaultItem);
				if (item.length > 0) {
					this.selectItem(item[0]);
				}
			}

		},
	},

};
</script>

<style scoped>

@media (max-width: 992px) {
  .search-select {
    width: 100% !important;
  }
}

.search-select {
	position: relative;
	width: 110%;
  padding-right: 15px;
}

.result-list {
	position: absolute;
	width: 95%;
	max-height: 200px;
	overflow-y: auto;
	z-index: 10;
	border-top: none;
}

.dropdown-item {
	cursor: pointer;
}

.dropdown-item.hover,
.dropdown-item.active {
	background-color: hsla(0, 0%, 5%, 0.1);
}


.clear-btn {
	cursor: pointer;
	color: #ccc;
	transition: color 0.2s ease-in-out;
}

.clear-btn:hover {
	color: hsl(0, 100%, 50%);
}

.bi-chevron-down {
	pointer-events: none;
	will-change: transform;
	transform: translateY(-50%) rotate(0);
	transition: transform 0.3s ease-in-out;
}

.rotate-180 {
	transform: translateY(-50%) rotate(180deg);
	/* Adjust translateY as per original translate-middle-y */
	transition: transform 0.3s ease-in-out;
}
</style>