<template>
	<!-- Pagination -->
	<nav class="d-flex justify-content-between py-2" aria-label="Page navigation" v-if="pagination">
		<ul class="pagination">
			<li class="page-item" :class="{ disabled: !pagination.prevPage }" >
				<button :disabled="!pagination.prevPage" class="page-link" @click="setPage(pagination.prevPage)"
						aria-label="Prev"><i class="ci-arrow-left me-2"></i>Prev</button>
			</li>
		</ul>
		<ul class="pagination">
			<!-- Mobile View -->
			<li class="page-item d-sm-none"><span class="page-link page-link-static">
					{{ pagination.currentPage }} / {{ pagination.numPages }}
				</span></li>
			<!-- Desktop View -->
      <li class="page-item d-none d-sm-block"
          v-for="page in pagination.pages"
          :key="page.num"
          :class="{ active: page.num === pagination.currentPage }">
        <button v-if="page.url" @click="setPage(page.num)" class="page-link">{{ page.num }}</button>
        <button v-else class="page-link page-link-static">{{ page.num }}</button>
      </li>
		</ul>
		<ul class="pagination">
			<li class="page-item" :class="{ disabled: !pagination.nextPage }">
				<button :disabled="!pagination.nextPage" class="page-link" @click="setPage(pagination.nextPage)"
						aria-label="Next">Next<i class="ci-arrow-right ms-2"></i></button>
			</li>
		</ul>
	</nav>
</template>

<script setup>

const props = defineProps({
	pagination: Object
});


// Define emits
const emit = defineEmits(['page-change']);

// Example setPage method
const setPage = (page) => {
	emit('page-change', page);

};
</script>

<style scoped>
.disabled {
	pointer-events: none;
	cursor:not-allowed;
	user-select: none;
}
</style>
