
/**
 *  Shopping Cart Drawer for vr23 template
 *    - Removes cart contents
 *    - Recalculates subtotal
 */
class ShoppingCartDrawer {

    static CART_DRAWER_WRAPPER_ID = "cartDrawerWrapper";
    static CART_WIDGET_CLASS = "widget-cart";

    constructor(cart_drawer_id) {
        this.cart_drawer_element        = document.getElementById(cart_drawer_id);

        this.side_cart_toggles          = document.getElementsByClassName('side_cart_toggle');
        this.side_cart_empty            = document.getElementsByClassName('side_cart_empty');
        this.nav_qty_spans              = document.getElementsByClassName('navbar_shopping_cart_qty');
        this.nav_subtotal_spans         = document.getElementsByClassName('navbar_shopping_cart_subtotal');
        this.handheld_nav_qty_spans     = document.querySelectorAll('.handheld-toolbar .navbar_shopping_cart_qty');

        this.remove_buttons = [];
        this.cart_drawer_wrapper = null;
        this.cart_widget = null;
        if (this.cart_drawer_element !== null) {
            this.cart_drawer_wrapper = this.cart_drawer_element.querySelector('#' + ShoppingCartDrawer.CART_DRAWER_WRAPPER_ID);
            this.cart_widget = this.cart_drawer_element.querySelector('.' + ShoppingCartDrawer.CART_WIDGET_CLASS);
        }
    }

    static init(cart_drawer_id) {
        const drawer = new ShoppingCartDrawer(cart_drawer_id);
        drawer.setupListeners();

        window.addEventListener('shopping_cart_contents_changed', (e) => {
            drawer.updateCartContents(drawer.cart_widget , e.detail);
        });
    }

    static contentsLoaded = (cartDrawer, products) => {
        return new Promise(function (resolve){
            cartDrawer.innerHTML = "";
            for (let [cart_key, product] of Object.entries(products)) {
                let itemPrototype = document.querySelector('#widget-item-prototype div').outerHTML;
                itemPrototype = itemPrototype.replace("__CART_KEY__", cart_key);
                itemPrototype = itemPrototype.replaceAll("__NAME__", product.name);
                itemPrototype = itemPrototype.replace("__DOLLARS__", product.dollars);
                itemPrototype = itemPrototype.replace("__CENTS__", product.cents);
                itemPrototype = itemPrototype.replace("__QTY__", product.qty);
                itemPrototype = itemPrototype.replace("__STACK_PRICE__", String(product.stack_price * 100));
                itemPrototype = itemPrototype.replaceAll("__PAGE_URL__", product.page_url);
                itemPrototype = itemPrototype.replace("data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=", product.img_src);
                itemPrototype = itemPrototype.replace("__PARENT_CART_KEY__", product.warranty_for_key ?? "0");
                cartDrawer.innerHTML += itemPrototype + "\n";
            }

            resolve();
        });
    };

    setupListeners() {
        if (this.cart_drawer_element === null) {
            return;
        }

        this.remove_buttons = this.cart_widget.querySelectorAll('.remove_cart_item')
        for (let button of this.remove_buttons) {
            button.addEventListener("click", (event) => {
                let cartKey = button.dataset.cartKey ?? false;
                this._hideProducts(cartKey, button.parentElement).then(() => {
                    this._calculateSubtotal();
                });
            })
        }
    }

    updateCartContents(cartDrawer, details) {
        if (Object.keys(details.products).length > 0) {
            ShoppingCartDrawer.contentsLoaded(cartDrawer, details.products).then(() => {
                this.side_cart_toggles[0].click();
                this.setupListeners();
            });
        }

        this.updateNavElements(details.qty, details.subtotal);
        if (details.qty > 0) {
            let formatted = this._formatMoney(details.subtotal/100);
            document.getElementById('subtotal_amount_wrapper').classList.remove('d-none');
            document.getElementById('subtotal_amount').innerHTML = formatted.dollars + '.<small>' + formatted.cents + '</small>';
        } else {
            document.getElementById('subtotal_amount_wrapper').classList.add('d-none');
            this._emptyCartMessage();
            this.cart_drawer_element.addEventListener('hidden.bs.offcanvas', () => {
                window.location.reload();
            });
        }
    }

    updateNavElements(qty, subtotal) {
        let stdFormatting = new Intl.NumberFormat('en-us', {style: 'currency', currency: "USD"});

        for (let el of this.nav_qty_spans) {
            el.innerHTML = String(qty);
        }

        for (let el of this.nav_subtotal_spans) {
            el.innerHTML = stdFormatting.format(subtotal/100);
        }

        if (qty > 0) {
            for (let el of this.side_cart_toggles) {
                el.classList.remove('d-none');
                el.classList.add('d-flex');
            }
            for (let el of this.side_cart_empty) {
                el.classList.add('d-none');
                el.classList.remove('d-flex');
            }

            for (let el of this.handheld_nav_qty_spans) {
                el.classList.remove('d-none');
            }
        } else {
            for (let el of this.side_cart_toggles) {
                el.classList.add('d-none');
                el.classList.remove('d-flex');
            }
            for (let el of this.side_cart_empty) {
                el.classList.remove('d-none');
                el.classList.add('d-flex');
            }

            for (let el of this.handheld_nav_qty_spans) {
                el.classList.add('d-none');
            }
        }
    }

    async _hideProducts(productCartKey, parentElement, fetchRemoveProduct = true) {
        if (!parentElement.classList.contains('show')) {
            return;
        }

        for (let button of this.remove_buttons) {
            if (button.dataset.hasOwnProperty('parentCartKey') && button.dataset.parentCartKey === productCartKey) {
                await this._hideProducts('', button.parentElement, false);
            }
        }

        parentElement.classList.remove('show');
        await this._removeElement(parentElement);
        if (fetchRemoveProduct) {
            await this._removeProduct(productCartKey);
        }
    }

    _removeElement = (parentElement) => new Promise((resolve) => {
        setTimeout(() => {
            parentElement.remove();
            resolve(true);
        }, 300);
    });

    _removeProduct(cartKey) {
        this.fetch_response_messages = [];

        const searchParams = new URLSearchParams();
        searchParams.set('cart_key', cartKey);
        searchParams.set('is_silent', "1");

        fetch('/shopping_cart_process.php?cart_action=remove_product', {
            method: 'POST',
            headers: {
                'Accept': 'application/json; q=0.01',
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: searchParams,
            cache: 'default'
        }).then((response) => {
            if (!response.ok) {
                throw new Error('Technical error, please refresh page.')
            }

            return response.json();
        }).then(data => {
            if (data.hasOwnProperty('messages') && data.messages.length > 0) {
                for (let message of data.messages) {
                    if (message[0] === 'danger') {
                        this.fetch_response_messages.push(message[1]);
                    }
                }
            }
        }).catch((error) => {
            this.fetch_response_messages.push(error.message);
        });
    }

    _calculateSubtotal() {
        let subtotal = 0;
        let cartItems = this.cart_widget.querySelectorAll('.widget-cart-item');
        for (let cartItem of cartItems) {
            let itemMeta = cartItem.querySelector('.widget-product-meta');
            subtotal += parseInt(itemMeta.dataset.stackPrice);
        }

        if (subtotal > 0) {
            let formatted = this._formatMoney(subtotal/100);
            document.getElementById('subtotal_amount').innerHTML = formatted.dollars + '.<small>' + formatted.cents + '</small>';
        } else {
            this._emptyCartMessage();
            this.cart_drawer_element.addEventListener('hidden.bs.offcanvas', () => {
                window.location.reload();
            });
        }
    }

    _formatMoney(amount) {
        let dollars = Math.floor(amount);
        let cents = Math.round((amount - dollars) * 100);
        if (cents === 100) {
            cents = 0;
            dollars++;
        }

        let formatting = new Intl.NumberFormat('en-us', {maximumFractionDigits: 0, style: 'currency', currency: 'USD'});

        return {
            dollars: formatting.format(dollars),
            cents: String(cents).padStart(2, '0')
        }
    }

    _emptyCartMessage() {
        let emptyCartHeader = document.createElement('h4');
        emptyCartHeader.textContent = 'Your cart is empty!';

        let emptyCartMessage1 = document.createElement('div');
        emptyCartMessage1.className = 'my-3';
        emptyCartMessage1.textContent = 'So put it in reverse and start adding some awesome products.';

        let emptyCartMessage2 = document.createElement('div');
        emptyCartMessage2.className = 'text-accent';
        emptyCartMessage2.innerHTML = 'Need help? Give us a shout at <br/><a href="tel:14809663040"><i class="bi bi-phone"></i>&nbsp;1-480-966-3040</a>';

        this.cart_widget.after(emptyCartHeader, emptyCartMessage1, emptyCartMessage2);
        this.cart_widget.remove();
    }
}

export default ShoppingCartDrawer;