<template>
  <div class="accordion-item border-0 pb-1 pb-xl-2">
    <h3 class="accordion-header">
      <button type="button" class="accordion-button p-0 pb-3" data-bs-toggle="collapse" data-bs-target="#price"
              aria-expanded="true" aria-controls="price">Price Range
      </button>
    </h3>

    <div class="accordion-collapse collapse show" id="price" style="margin-top: 5px;">
      <div class="accordion-body p-0 pb-4 mb-1 mb-xl-2 pt-2">
        <div class="range-slider" ref="rangeSlider" :data-start-min="min" :data-start-max="max" :data-min="min"
             :data-max="max" :data-step="step" :data-currency="currency">
          <div class="d-flex">
            <div class="w-50 w-sm-100 pe-2 me-2">
              <div class="input-group input-group-sm"><span class="input-group-text">$</span>
                <input type="text" class="form-control range-slider-value-min" id="priceRangeMin" v-model="minValue" @input="validateNumberInput('minValue')" placeholder="min">
              </div>
            </div>
            <div class="w-50 w-sm-100 ps-2">
              <div class="input-group input-group-sm"><span class="input-group-text">$</span>
                <input type="text" class="form-control range-slider-value-max" id="priceRangeMax" v-model="maxValue" @input="validateNumberInput('maxValue')" placeholder="max">
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-start mt-2">
          <button type="button" class="btn btn-outline-secondary btn-sm me-2" @click="resetButtonClicked">Reset</button>
          <button id="priceRangeBtn" type="button" class="btn btn-outline-secondary btn-sm"
                  @click="applyButtonClicked">Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '../store';
export default {
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 10
    },
    currency: {
      type: String,
      default: '$'
    }
  },
  data() {
    return {
      minValue: '',
      maxValue: '',
      slider: null,
      store
    };
  },
  mounted() {
    let url = new URL(window.location.href);
    let priceParam = url.searchParams.get("price");

    if (priceParam) {
      let [min, max] = priceParam.split("-").map(Number);
      this.minValue = min;
      this.maxValue = max;
    }
  },
  methods: {
    validateNumberInput(field) {
      this[field] = this[field].replace(/\D/g, '');
    },
    applyButtonClicked() {
      let minPriceInput = document.getElementById('priceRangeMin');
      let maxPriceInput = document.getElementById('priceRangeMax');

      let minPrice = minPriceInput.value.trim();
      let maxPrice = maxPriceInput.value.trim();

      // Convert input values to numbers to remove leading zeros
      minPrice = minPrice ? parseInt(minPrice, 10).toString() : "0"; // Ensure minPrice is at least "0"
      maxPrice = maxPrice ? parseInt(maxPrice, 10).toString() : ""; // Remove leading zeros but allow empty

      // Set values back to input fields to reflect the cleaned numbers
      minPriceInput.value = minPrice;
      maxPriceInput.value = maxPrice;

      // Ensure minPrice has a value
      if (!minPrice || isNaN(minPrice) || parseFloat(minPrice) < 0) {
        minPrice = 0; // Set minPrice to 0 if empty or invalid
        this.minValue = 0;
        minPriceInput.value = 0;
      }

      // Validate maxPrice: If empty, show built-in validation
      if (!maxPrice || isNaN(maxPrice) || parseFloat(maxPrice) <= 0) {
        maxPriceInput.setCustomValidity("Max price is required and must be greater than 0.");
        maxPriceInput.reportValidity();
        return;
      } else {
        maxPriceInput.setCustomValidity("");
      }

      let price = minPrice + '-' + maxPrice;
      this.minValue = minPrice;
      this.maxValue = maxPrice;

      this.store.setSelectedFilter('price', price);
      this.$emit('filters-updated', this.selectedFacets);
      this.modifiedData = true;

      let currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('price', price);
      window.location.href = currentUrl.href;
    },
    resetButtonClicked() {
      this.minValue = '';
      this.maxValue = '';

      let minPriceInput = document.getElementById('priceRangeMin');
      let maxPriceInput = document.getElementById('priceRangeMax');

      let minPrice = minPriceInput.value.trim();
      let maxPrice = maxPriceInput.value.trim();

      // Convert inputs to remove leading zeros
      minPrice = minPrice ? parseInt(minPrice, 10).toString() : "0";
      maxPrice = maxPrice ? parseInt(maxPrice, 10).toString() : "";

      if ((!minPrice || minPrice === "0") && (!maxPrice || maxPrice === "0")) {
        minPriceInput.value = "";
        maxPriceInput.value = "";
        this.minValue = "";
        this.maxValue = "";
        return;
      }

      this.$emit('facet-clicked', 'price', {
        'value': '',
        'undo': true
      });

      let currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete('price');
      window.location.href = currentUrl.href;
    },
  },
}
</script>