<template>
	<teleport to="body">
		<div class="modal fade" id="vehicleModal" tabindex="-1" aria-labelledby="vehicleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="vehicleModalLabel">Select Your Vehicle</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body custom-modal-background">
						<div class="row gx-2">
							<div class="col">
								<search-select :search-items="yearSelectValues" v-model="selectedYear"
											   aria-label="Vehicle Year" label="Year" @update:modelValue="fetchMakes" />
							</div>
							<div class="col">
								<search-select :search-items="makeSelectValues" v-model="selectedMake"
											   aria-label="Vehicle Make" :disabled="!selectedYear"
											   :title="!selectedYear ? 'Please select a year first' : ''" label="Make"
											   @update:modelValue="fetchModels" />
							</div>
							<div class="col">
								<search-select :search-items="modelSelectValues" v-model="selectedModel"
											   aria-label="Vehicle Model" :disabled="!selectedMake"
											   :title="!selectedMake ? 'Please select a make first' : ''"
											   label="Model" />
							</div>
						</div>
						<div class="mt-3">
							<button class="btn btn-primary w-100" @click="handleVehicleSelected"
									:disabled="!isSelectionComplete">
								Select Vehicle
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
import SearchSelect from '../common/SearchSelect.vue';

export default {
	name: 'VehicleModal',
	components: {
		SearchSelect,
	},
	data() {
		return {
			modal: null,
			years: [],
			makes: [],
			models: [],
			selectedYear: null,
			selectedMake: null,
			selectedModel: null,
		};
	},
	computed: {
		isSelectionComplete() {
			return this.selectedYear && this.selectedMake && this.selectedModel;
		},
		yearSelectValues() {
			return this.years.map(year => ({
				value: year,
				label: year,
			}));
		},
		makeSelectValues() {
			return this.makes.map(make => ({
				value: make.id,
				label: make.label,
			}));
		},
		modelSelectValues() {
			return this.models.map(model => ({
				value: model.id,
				label: model.label,
			}));
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.modal = new bootstrap.Modal(document.getElementById('vehicleModal'));
			this.fetchYears();
		});
	},
	methods: {
		async fetchYears() {
			try {
				const response = await fetch('/ymm-json.php?request=years');
				const data = await response.json();
				this.years = data.data.map(year => year.id);
			} catch (error) {
				console.error('Error fetching years:', error);
			}
		},
		async fetchMakes() {
			if (!this.selectedYear) return;
			try {
				const response = await fetch(`/ymm-json.php?request=makes&year=${this.selectedYear}`);
				const data = await response.json();
				this.makes = data.data.map(make => ({ id: make.id, label: make.label }));
				this.selectedMake = null;
				this.selectedModel = null;
			} catch (error) {
				console.error('Error fetching makes:', error);
			}
		},
		async fetchModels() {
			if (!this.selectedMake) return;
			try {
				const response = await fetch(`/ymm-json.php?request=models&year=${this.selectedYear}&make=${this.selectedMake}`);
				const data = await response.json();
				this.models = data.data.map(model => ({ id: model.id, label: model.label }));
				this.selectedModel = null;
			} catch (error) {
				console.error('Error fetching models:', error);
			}
		},

		async setVehicle() {
			if (!this.isSelectionComplete) return;

			const selectedVehicle = {
				year: this.selectedYear,
				make_id: this.selectedMake,
				model_id: this.selectedModel,
			};
			
		
			fetch(`/ymm-json.php?` + new URLSearchParams({
				request: 'set',
				year: this.selectedYear,
				make: this.selectedMake,
				model: this.selectedModel,
			}))
				.then(response => response.json())
				.then(data => {
					console.log(data);
				})
				.catch(error => {
					console.error('Error setting vehicle:', error);
				});
		},
		handleVehicleSelected() {
			if (!this.isSelectionComplete) return;
			this.setVehicle();
			const selectedVehicle = {
				year: this.selectedYear,
				make_id: this.selectedMake,
				model_id: this.selectedModel,
				make_name: this.makes.find(make => make.id === this.selectedMake)?.label,
				model_name: this.models.find(model => model.id === this.selectedModel)?.label,
			};

			this.$emit('vehicle-selected', selectedVehicle);
			this.modal.hide();

			
		},
		showModal() {
			if (this.modal) {
				this.modal.show();
			} else {
				console.error('Modal is not initialized');
			}
		},
	},
};
</script>

<style scoped>
/* These styles are likely not needed anymore, but keeping them just in case */
#vehicleModal {
	z-index: 1056 !important;
}

.modal-backdrop {
	z-index: 1055 !important;
}

.custom-modal-background {
	background-image: url('https: //cdn.vividracing.com/file/template_backgrounds/0_default_1709229963.webp');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 20px;
	border-radius: 0 0 0.3rem 0.3rem;
}

.modal-content {
	background-color: rgba(255, 255, 255, 0.9);
	backdrop-filter: blur(5px);
}

.modal-header {
	border-bottom: none;
}

.btn-primary {
	background-color: #007bff;
	border-color: #007bff;
}

.btn-primary:hover {
	background-color: #0056b3;
	border-color: #0056b3;
}
</style>